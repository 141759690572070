window._navDesktopV2 = (function () {
	const mainNavEl = document.querySelector('.cnf_main-nav');
	if (!mainNavEl) return;

	let navDesktop_isMobile = !window.matchMedia('(min-width: 980px)').matches;
	const mainNavBtns = document.querySelectorAll(
		'.cnf_main-nav .cnf_main-nav__list-item--top-level button:not(.cnf_main-nav__close-button)'
	);
	const mainNavCloseBtns = document.querySelectorAll('.cnf_main-nav .cnf_main-nav__close-button');
	const allButtons = document.querySelectorAll('.cnf_main-nav button');

	let currentMainHoveredBtn;
	let insuranceTabHovered = false;
	let timer;
	let navOpen = false;

	document.addEventListener('DOMContentLoaded', function () {
		createDimmer();
	});

	mainNavEl.addEventListener('mouseleave', function () {
		if (!navOpen) return;
		setTimeout(function () {
			closeNav();
		}, 250);
	});

	// Set isMobile on browser resize
	window.addEventListener('resize', function () {
		if (window.matchMedia('(min-width: 980px)').matches) {
			navDesktop_isMobile = false;
		} else {
			navDesktop_isMobile = true;
			closeNav();
		}
	});

	document.body.addEventListener('keydown', function (event) {
		if (event.code === 'Tab' && !event.shiftKey) {
			if (document.activeElement.closest('.cnf_main-nav') === null && navOpen) closeNav();
		}
	});

	document.querySelector('header').addEventListener('keydown', function (event) {
		if (navDesktop_isMobile) return;
		if (event.code === 'Tab' && !event.shiftKey) {
			if (
				(document.activeElement.id === document.querySelector('[data-product="car-care-_-selling"] > button') &&
					!mainNavEl.classList.contains('cnf_nav-product--carcare')) ||
				(document.activeElement.id === document.querySelector('[data-product="broadband-_-mobile"] > button') &&
					!mainNavEl.classList.contains('cnf_nav-product--broadband'))
			) {
				// jshint ignore:line
				closeNav();
			} else if (
				document.activeElement ===
					document.querySelector(
						'[data-product="car-care-_-selling"] .cnf_main-nav__container--bottom-level .cnf_main-nav__close-button'
					) ||
				document.activeElement ===
					document.querySelector(
						'[data-product="broadband-_-mobile"] .cnf_main-nav__container--bottom-level .cnf_main-nav__close-button'
					)
			) {
				// jshint ignore:line
				closeNav();
			}
		} else if (event.code === 'Tab' && event.shiftKey) {
			if (document.activeElement.classList.contains('cnf_logo')) {
				closeNav();
			}
		}
	});

	document.getElementById('skip-nav-link').addEventListener('click', function () {
		closeNav();
	});

	// Handles enter press to activate button clicks
	for (let i = 0; i < allButtons.length; i++) {
		allButtons[i].addEventListener('keypress', function (event) {
			onEnterPress(event);
		});
	}

	// Handles close nav button
	for (let i = 0; i < mainNavCloseBtns.length; i++) {
		mainNavCloseBtns[i].addEventListener('click', function () {
			closeNav();
		});
	}

	// Handles nav hover
	for (let i = 0; i < mainNavBtns.length; i++) {
		mainNavBtns[i].addEventListener('mouseenter', function (event) {
			if (navDesktop_isMobile) return;
			timer = setTimeout(function () {
				onMainNavHover(event);
			}, 250);
		});
	}

	// This will clear the hover delay timer when the mouse leaves the insurance sub menu. Stops unwanted animations.
	for (let i = 0; i < mainNavBtns.length; i++) {
		mainNavBtns[i].addEventListener('mouseleave', function (event) {
			if (event.target.classList.contains('cnf_delay-hover')) clearTimeout(timer);
		});
	}

	// Utility classes
	function removeOrAddClass(element, className, add) {
		if (navDesktop_isMobile) return;
		if (add) {
			element.forEach(function (el) {
				el.classList.add(className);
			});
		} else {
			element.forEach(function (el) {
				el.classList.remove(className);
			});
		}
	}

	// Get the button id and pass open the right secondary menu
	function onMainNavHover(event) {
		if (navDesktop_isMobile) return;
		document.querySelector('.cnf_nav-dimmer').classList.add('cnf_is-shown');
		currentMainHoveredBtn = event.target;
		toggleSecondaryMenu();
	}

	// Handles opening all sub menus, unless it is the insurance tab then this function will pass to handleInsuranceTabHovered();
	function toggleSecondaryMenu() {
		if (navDesktop_isMobile) return;
		if (
			navOpen &&
			currentMainHoveredBtn.parentElement.dataset.product === 'insurance' &&
			mainNavEl.classList.contains('cnf_nav-insurance')
		)
			return;
		addNavClasses(currentMainHoveredBtn);
		navOpen = true;
		if (
			(currentMainHoveredBtn.classList.contains('cnf_second-level-links--mobile') &&
				currentMainHoveredBtn.classList.contains('cnf_main-nav__options--mid-level')) ||
			currentMainHoveredBtn.parentElement.dataset.product === 'insurance'
		) {
			// Insurance tab hovered, handle this separately.
			insuranceTabHovered = true;
			handleButtonActiveStates(currentMainHoveredBtn);
			handleInsuranceTabHovered();
			return;
		} else {
			insuranceTabHovered = false;
		}
		// Is not insurance tab that has been hovered.
		handleButtonActiveStates(currentMainHoveredBtn);
		const secondaryMenuToOpen = currentMainHoveredBtn.nextElementSibling.nextElementSibling;
		if (!secondaryMenuToOpen) return;
		const alreadyOpenSecondaryMenu = document.querySelectorAll('.cnf_main-nav .cnf_nav--is-open');
		if (alreadyOpenSecondaryMenu.length > 0) removeOrAddClass(alreadyOpenSecondaryMenu, 'cnf_nav--is-open', false);
		secondaryMenuToOpen.classList.add('cnf_nav--is-open');
	}

	function addNavClasses(currentMainHoveredBtn) {
		if (navDesktop_isMobile) return;
		mainNavEl.className = 'cnf_main-nav';

		const productClassMap = {
			insurance: 'cnf_nav-product--motor',
			utilities: 'cnf_nav-product--utilities',
			energy: 'cnf_nav-product--energy',
			finance: 'cnf_nav-product--finance',
			'car-care-_-selling': 'cnf_nav-product--carcare',
			'broadband-_-mobile': 'cnf_nav-product--broadband',
		};
		const subProductClassMap = {
			'motor-primary': 'cnf_nav-product--motor',
			home: 'cnf_nav-product--home',
			life: 'cnf_nav-product--life',
			travel: 'cnf_nav-product--travel',
			pet: 'cnf_nav-product--pet',
			'business-primary': 'cnf_nav-product--business',
		};
		const insuranceClassName = 'cnf_nav-insurance';

		const { product, subProduct } = currentMainHoveredBtn.parentElement.dataset;
		if (subProductClassMap[subProduct]) mainNavEl.classList.add(insuranceClassName);

		const navClassName = productClassMap[product] || subProductClassMap[subProduct];
		if (navClassName) mainNavEl.classList.add(navClassName);
	}

	// Handles adding classes to show that a button is active
	function handleButtonActiveStates(button) {
		if (navDesktop_isMobile) return;
		const isMainInsuranceBtn = button.parentElement.dataset.product === 'insurance';
		if (isMainInsuranceBtn && mainNavEl.classList.contains('cnf_nav-insurance')) return;
		if (
			document.querySelector('.cnf_main-nav .cnf_main-nav__container--mid-level--ul .cnf_nav--btn-is-active') &&
			isMainInsuranceBtn
		)
			return;
		const activeButtons = document.querySelectorAll('.cnf_main-nav .cnf_nav--btn-is-active');
		removeOrAddClass(activeButtons, 'cnf_nav--btn-is-active', false);
		if (insuranceTabHovered && isMainInsuranceBtn) {
			// Main insurance button hovered
			removeOrAddClass(
				[document.querySelector('[data-sub-product="motor-primary"] > button'), button],
				'cnf_nav--btn-is-active',
				true
			);
			return;
		} else if (insuranceTabHovered && !isMainInsuranceBtn) {
			// Insurance mid-level option hovered
			document.querySelector('[data-product="insurance"] button').classList.add('cnf_nav--btn-is-active');
		}
		button.classList.add('cnf_nav--btn-is-active');
	}

	// Opens the correct menus when the insurance tab is hovered. Insurance has an extra menu so needs to be treated slightly different.
	function handleInsuranceTabHovered() {
		if (navDesktop_isMobile) return;
		const isMainInsuranceBtn = currentMainHoveredBtn.parentElement.dataset.product === 'insurance';
		const insuranceMidLevelMenu = document.querySelector(
			'[data-product="insurance"] .cnf_main-nav__container--mid-level'
		);
		if (isMainInsuranceBtn) {
			const isOpenMenus = document.querySelectorAll('.cnf_main-nav .cnf_nav--is-open');
			if (isOpenMenus.length > 0) removeOrAddClass(isOpenMenus, 'cnf_nav--is-open', false);
			const motorBottomMenu = document.querySelector(
				'[data-sub-product="motor-primary"] .cnf_main-nav__container--bottom-level'
			);
			removeOrAddClass([insuranceMidLevelMenu, motorBottomMenu], 'cnf_nav--is-open', true);
		} else {
			const menuToClose = insuranceMidLevelMenu.querySelector('.cnf_nav--is-open');
			const menuToOpen = insuranceMidLevelMenu.querySelector('.cnf_nav--btn-is-active').nextElementSibling;
			menuToClose.classList.remove('cnf_nav--is-open');
			menuToOpen.classList.add('cnf_nav--is-open');
		}
	}

	function createDimmer() {
		const dimmerElement = document.createElement('div');
		dimmerElement.setAttribute('class', 'cnf_nav-dimmer cnf_is-injected');
		document.body.appendChild(dimmerElement);
	}

	// Activates hover functions if user is using keyboard and has pressed enter on a menu option.
	function onEnterPress(event) {
		if (navDesktop_isMobile) return;
		if (event.key === 'Enter') onMainNavHover(event);
	}

	// Closes nav on close nav button click
	function closeNav() {
		if (navDesktop_isMobile) return;
		const allOpenMenus = document.querySelectorAll('.cnf_main-nav .cnf_nav--is-open');
		removeOrAddClass(allOpenMenus, 'cnf_nav--is-open', false);
		const allActiveButtons = document.querySelectorAll('.cnf_main-nav .cnf_nav--btn-is-active');
		removeOrAddClass(allActiveButtons, 'cnf_nav--btn-is-active', false);
		document.querySelector('.cnf_nav-dimmer').classList.remove('cnf_is-shown');
		navOpen = false;
	}

	return {
		closeNav: closeNav,
	};
})();
